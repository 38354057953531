import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
// import { ReactComponent as Hearing } from "images/hearing.svg";
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Hearing from "../../images/hearing.svg"
import Reading from "../../images/reading.svg"
import Speak from "../../images/speaker.png"
import { SectionHeading } from "components/misc/Headings.js";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"
import { useSpeechSynthesis,useSpeechRecognition  } from 'react-speech-kit';
const Section = styled.div`
	${tw`relative xs:px-10 py-20 md:py-10 bg-whiteSmoke`}
`;
const Container = styled.div`
	${tw`relative text-left max-w-[1280px] mx-auto`}
`;
const IconContainer = tw.i``;
const Heading = tw(SectionHeading)`w-full  md:mb-20 max-w-[1170px] mx-auto`;
const MainBlock = styled.div`
	${tw`flex flex-col md:flex-row justify-between gap-6 w-full`}
`;
const LeftSection =  styled.div`
	${tw`w-full md:w-1/2`}
	h3{
		${tw`text-2xl text-center md:text-left md:text-3xl text-blackStep-200 font-semibold md:pb-[60px] md:pt-[30px] py-4 leading-tight md:leading-loose`}
	}
	.messageBlock{
		${tw`border border-purple-250 p-1 md:p-3 text-lg md:text-xl text-blackStep-200 font-normal md:font-semibold w-full md:w-[290px]`}
	}
`;
const RightSection = styled.div`
	${tw`w-full md:w-1/2 md:pt-[84px]`}
	h3{
		${tw`text-center md:text-left text-2xl md:text-3xl text-blackStep-200 font-semibold md:pb-[60px] md:pt-[30px] py-4 leading-tight md:leading-loose`}
	}
	.speakBlock{
		${tw`border border-purple-250 p-2 md:p-3 w-full  md:w-[310px] `}
	}
	.speakSec{
		${tw`inline-flex items-center`}
		p{
			${tw`ml-1 text-xl text-gray-600 text-center md:text-left font-normal md:font-semibold hover:underline no-underline`}
		}
	}
`;
export default ({
	heading = "Enhance Your Reading Skills",

}) => {
const germanPara= 'Danke, auf Wiedersehen!';
const [paraText,setParaText] = useState(germanPara);
const { speak, cancel, voices } = useSpeechSynthesis();
const [show,setShow] = useState(false);
const { listen, listening, stop } = useSpeechRecognition({
	onResult: (result) => {
	  setParaText(result);
	},
});
const handleSpeak =()=>{
	if(show==false){
		speak({ text: paraText, voice:voices[5] });
	setShow(!show);
	}else{
		cancel();
	setShow(!show);
	}
	
 }
  return (
  	<Section>
	    <Container>
	    	<Heading>{heading}</Heading>
	    	<MainBlock>
				<LeftSection>	
					<h3>Read below text in German</h3>
					<div className="messageBlock">
						{paraText}
					</div>
				</LeftSection>
				<RightSection>	
					<h3>Need Help in Pronounciation.</h3>
					<div className="speakBlock">
						<a onClick={() => handleSpeak()} href="javascript:void(0)" className="speakSec">
							<img src={Speak} />
							<p>Let's Speak it for you</p>
						</a>
					</div>
				</RightSection>
			</MainBlock>
	    </Container>
	</Section>
  );
};
