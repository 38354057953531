import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import fitdeutsch from "images/fit in deutsch final A1.jpg";
import gols from "images/goals.png";
import VideoContentImage from "images/youngminds_language.png";
import coursedetail from "images/course detail.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";

const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight text-left px-6 md:px-0 lg:px-0`;
const Gtext = tw.span`text-brand-500 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Young Minds - Simplifying German Language Learning"
        meta={[
          {
            name: "description",
            content:
              "Streamline and Simplify German Language Learning for Global Career Goals",
          },
          {
            property: `og:title`,
            content: "Young Minds - Simplifying German Language Learning",
          },
          {
            property: `og:description`,
            content: "Streamline and Simplify German Language Learning for Global Career Goals",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/fit_in_deutsch_1.jpg",
          },
          {
            property: `og:image:alt`,
            content: "YoungMinds Fit in Deutsch 1",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"https://youngminds.pro/fit-in-deutsch-1",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />

      <AnimationRevealPage>
        <MainBanner
          roundedHeaderButton={true}
          mainTitle={
            <Title>
              <Gtext>Fit in Deutsch - 1</Gtext>
            </Title>
          }
          subTitle="A Proficiency Exam to Assess Ability to Communicate in Everyday Situations and Covers Basic Grammar, Vocabulary, and Communication skills for Learners of Age Group 10 to 16 Years."
          buttonText="Let's Talk"
          buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to know more About Fit in Deutsch 1 Program"
          imageSrc={fitdeutsch}
          paragraph="Academic Partner - Language Pantheon"
          showClient={false}
          text="Fit In Deutsch 1"
          knowmoreUrl={innerPages.aboutfitindeutsch1.url}
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              General Info About{" "}
              <HighlightedText>Fit in Deutsch 1</HighlightedText>
            </>
          }
          description={
            <>
              <p>
                {" "}
                The Fit in Deutsch 1 exam is designed for children and
                adolescents <BoldTag>aged between 10 and 16 Years</BoldTag> who
                want to test their German language proficiency. This
                certification confirms that candidates have gained fundamental
                language abilities and corresponds to level A1 on the Common
                European Framework of Reference for Languages (CEFR) six-level
                competence scale
              </p>
              <br />
              <BoldTag>Passing the Exam Demonstrates ...</BoldTag>
              <p>
                1. you can ask and reply to simple questions as well as make and
                respond to requests,
                <br />
                2. you can write about yourself and others using simple
                expressions and sentences,
                <br />
                3. you can understand conversations and texts about familiar
                topics.
              </p>
              <br />
              <BoldTag>Exam Pattern of Fit in Deutsch 1</BoldTag>
              <p>
                The Goethe-Zertifikat A1: Fit in Deutsch 1 exam comprises of 4
                sections -{" "}
                <HighlightedText>
                  reading, listening, writing and speaking
                </HighlightedText>{" "}
                sections. The speaking section of the exam is completed in a
                group. The exam is{" "}
                <BoldTag>
                  administered and evaluated in the same way all over the world
                </BoldTag>
                .
              </p>
            </>
          }
          imageSrc={coursedetail}
          subheading="First Step for Young Learners Towards German Proficiency"
        />
        <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={gols}
          steps={[
            {
              mainIcon: "",
              heading: "Are a Study Abroad Aspirant",
              description:
                "Germany is the Top Destination for Graduation and Post Graduation.",
            },

            {
              mainIcon: "",
              heading: "Want to Learn Basics of German",
              description:
                "Take the First Step to German Proficiency, Open the World of Opportunities.",
            },

            {
              mainIcon: "",
              heading: "Wish to Get Global Certification",
              description:
                "Get an Official and Internationally recognized Goethe Certificate.",
            },
          ]}
        />

        <Faqs
          textOnLeft={false}
          buttonUrl="https://wa.me/918826622806/?text=Hi, I need more info on Fit in Deustch Exam ..."
          buttonText="Lets Connect"
          description="Here are some frequently asked questions about Goethe-Zertifikat A1: Fit in Deutsch 1 Exam, feel free to reach out in case of we missed out something."
          faqs={[
            {
              question:
                "What are the Eligibility / Requirements of this Exam ??",
              answer:
                "<p>The Goethe-Zertifikat A1: Fit in Deutsch 1 is a German exam for children and young people between 10 and 16 years of age.</p><p>The Goethe-Institut exams are available to all interested parties and can be taken regardless of age and nationality.</p><p>To sit the Goethe-Zertifikat A1: Fit in Deutsch 1 exam, candidates must have German language skills corresponding to the first level of competence (A1) of the Common European Framework of Reference for Languages (CFR).</p>",
            },
            {
              question: "How is Speaking Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to introduce yourself, ask questions on everyday matters and answer simple questions. You will also be required to make requests and respond to your conversation partner in situations with which you are familiar.</p><p><b>Duration of the Speaking Ability Test is 15 Mins</b></p>",
            },
            {
              question: "How is Listening Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to listen to short everyday conversations, personal telephone messages, information on the radio and similar recordings and complete various exercises on what you have heard.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Reading Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to read texts such as brief notes, classified advertisements, descriptions of people, simple letters to the editor or short newspaper articles and complete exercises on these texts.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Writing Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to reply to an email, letter, advertisement or similar piece of correspondence.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
          ]}
        />
        <Testimonials
          subheading="Learn German from the Best Institute in India"
          heading="Our Students Love Us."
          description={
            <>
              <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is
              that we work with you from start to finish. We pride ourselves in
              being fully engaged with your german learning journey, to help you
              open global opportunities for education and career growth.
            </>
          }
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
          textOnLeft={true}
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
              heading:
                "Didn’t Expected Language Learning can be So Interesting & Engaging",
              quote:
                "My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
              customerName: "Showick Thorpe",
              customerTitle: "Founder, V&S Thorpes Education Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
              heading: "Love the Teaching Experience and Technology Tools",
              quote:
                "I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
              customerName: "Amit Chawla",
              customerTitle: "FCA, Content Creator at EY",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
              heading: "Individualized Attention & Effective Learning",
              quote:
                "We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
              customerName: "Achal Gupta",
              customerTitle: "CEO, Kiran Prakashan",
            },
          ]}
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.germanbylanguagepantheon.url}
          heading={<Heading>Delivering Best <Gtext>German</Gtext> Language Courses Powered by <Ltext>Language Pantheon</Ltext>  Institute</Heading>}
          description="Language Pantheon is the Best Institute for German Language Learning, having a faculty team of over 100 teachers, streaming live german courses in 40+ countries and training over 8000 learners across the globe. Language Pantheon has <b>Unparallel Track Record</b> of over 15 Years for German Language Coaching and nearly <b>100% Success Rate</b>.<p>Young Minds delivers India's Top Most German language courses on your mobiles / laptops, powered by AI Enabled App with Live and Recorded Videos</p><p>Our Courses are Focused on <b>Fit in Deustch & Other Goethe Certifications and CBSE Curriculum for Classes 7 to 12.</b></p>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
      
        <FastestGrowing />
        <PreFooter fromPage = "About Fit in Deutsch 1" formheading = "Learning a Foreign Language in School Life Amplies Your Career Prospects and Personality" formsubheading = "Get More Details About the Program." heightlightText="Lets Start Learning"/>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
