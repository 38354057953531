import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import MobileImage from "images/phone.png";
import FastImg from "images/Fast.png";
import FocusedImg from "images/Focused.png";
import LearningImg from "images/Personalized.png";
import AccessImg from "images/access.png";


const Container = tw.div`relative bg-blue-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full mb-20 text-left md:text-center lg:text-center px-6`;
const Description = tw(SectionDescription)`w-full text-left md:text-center lg:text-center`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-left md:text-center lg:text-center sm:text-left lg:w-1/3 h-full`}
  .featureIcon {
    ${tw`sm:mx-auto lg:mx-0`}
  }
  .points{
    ${tw`px-10 text-left md:text-center lg:text-center mb-5 lg:px-0 lg:text-left first:lg:mb-20`}
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-semibold text-2xl leading-none block`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-normal text-black leading-6`}
  }
  .mobImg{
    ${tw`mb-5 w-full text-left md:text-center lg:text-center`}
    img{
      ${tw`mx-auto`}
    }
  }
`;


export default ({ heading = "Online learning benefits and features" }) => {
 

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        <Card>
          <div>
            <div className="points">
              <img className="featureIcon" src={FastImg} alt="" />
              <h3 className="title">Fast Progress</h3>
              <p className="description">Learn the things that you need to, You make fast progress by skipping material you don’t need to learn or practice.</p>
            </div>
            <div className="points">
              <img className="featureIcon" src={FocusedImg} alt="" />
              <h3 className="title">Focused</h3>
              <p className="description">There’s no travelling hassles and hectic schedules to get in the way of Learning. Use Every Bit of Your Precious Time.</p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="mobImg">
            <img src={MobileImage} alt="" />
          </div>
        </Card>
        <Card>
          <div>
            <div className="points">
              <img className="featureIcon" src={LearningImg} alt="" />
              <h3 className="title">Personalized Learning</h3>
              <p className="description">Keep learning relevant and suitably challenging for every individual learner. The program uses AI to map your knowledge and tailor the course around what you need to learn to progress quickly and effectively.</p>
            </div>
            <div className="points">
              <img className="featureIcon" src={AccessImg} alt="" />
              <h3 className="title">Easy Accessible</h3>
              <p className="description">Take your language learning with you to make faster progress with bite-sized study sessions throughout the day – use the app while commuting, waiting in line, or taking your lunch break.</p>
            </div>
          </div>
        </Card>
      </ThreeColumnContainer>
    </Container>
  );
};
