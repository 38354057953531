import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/AurumHeroContent.js";
import BlogMainContent from "components/hero/BlogMainContent.js";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import BlogSummary from "components/cards/BlogSummary.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { serverUtilities } from "../services/serverutilities.service";
import axios from "axios";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Simplified On-Boarding",
  highlightedtext = "Get Started.",
  heading = "Easy to",
  CheckIcon = Checkicon,
}) => {
  const url = window.location.href;
  //console.log(url);
  //alert(url);
  let spliturl = url.split("/").pop();
  const urlgerman = url;

  const splitPart = urlgerman.split("/")[3]; // "german-language"
  let titleBlog = spliturl.replace(/-/g, " ");
  titleBlog = titleBlog.replace(/\b\w/g, (x) => x.toUpperCase());
  //alert(spliturl);
  console.log(splitPart);
  const [blogData, setBlogData] = useState("0");
  const [relatedblogData, setrelatedBlogData] = useState("0");
  const [blogProds, setBlogProds] = useState("0");
  const [blogBanners, setBlogBanners] = useState("0");
  let vals = "0";
  let slug = url.name;

  let URI = "https://duxyz.b-cdn.net/0/" + spliturl;
  URI = "https://youngminds.pro/tt/" + spliturl;

  const requestOptions = {
    method: "GET",
    headers: {},
  };
  //const res = axios.get(URI);
  //console.log(res);

  // let blogData = res.data.vals;
  // console.log("blogData==",blogData)
  // let relatedblogData = res.related;
  //console.log("res==", res);
  //let blogData = '0';
  //let relatedblogData = '0';

  //alert ("1");
  /*let slug = url.name;*/
  useEffect(() => {
    //if (spliturl != undefined && spliturl != "") 


    if (blogData == "0") {
    const res = axios.get(URI);
    console.log(res);
    const bD = res.then((data) => {
      console.log(data);
      setBlogData(data.data.vals);
      setrelatedBlogData(data.data.related);
      //blogData = data.data.vals;
      //relatedblogData = data.data.related;
      console.log(data.data.related);
      let v = data.data.vals[0].id + "--" + data.data.vals[0].keywords.replace(" ","-");
      v = v.toLowerCase();
      serverUtilities.getBlogProds(v.toString()).then((resData) => {
        console.log(resData);
        //console.log(resData.vals.vals);
        if (resData) {
          setBlogProds(resData.prods);
          //titleBlog = resData.vals.title;
          setBlogBanners(resData.banners);
          //val = resData.status;
        }
      });
      
    });
    console.log(blogData);
  }

    
  }, []);
  // console.log(blogData);
  //console.log(vals);
  // if ((vals == '0') && (blogData!=null))
  // {}
  // else
  return (
    
    blogData != "0" &&
    relatedblogData != "0" && (
      <>
        <Helmet
          title={titleBlog != null && titleBlog}
          meta={[
            {
              name: "description",
              content: blogData != null && blogData[0].descrip,
            },
            {
              property: `og:title`,
              content:
                blogData != null && blogData[0].title
                  ? blogData[0].title
                  : "Young Minds - Simplifying German Language Learning",
            },
            {
              property: `og:description`,
              content:
                blogData != null && blogData[0].descrip
                  ? blogData[0].descrip
                  : "Streamline and Simplify German Language Learning for Global Career Goals",
            },
            {
              property: `og:image`,
              content: "https://aurumfiles.b-cdn.net/fit_in_deutsch_1.jpg",
            },
            {
              property: `og:image:alt`,
              content: "YoungMinds Fit in Deutsch 1",
            },
            {
              property: `og:image:width`,
              content: "500",
            },
            {
              property: `og:image:height`,
              content: "500",
            },
            {
              property: `og:url`,
              content: url ? url : "https://youngminds.pro/fit-in-deutsch-1",
            },
          ]}
        />
        <AnimationRevealPage>
          <BlogMainContent
            roundedHeaderButton={true}
            mainTitle={blogData != null && blogData[0].title}
            subTitle={blogData != null && blogData[0].descrip}
            blogBanners={blogBanners != null && blogBanners}
            buttonText="Let's Talk"
            buttonUrl="https://wa.me/918920403902/?text=Hi, I would like to Know More About Related Courses."
            imageSrc={
              blogBanners != null && blogBanners.header_img
                ? blogBanners.header_img
                : "https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
            }
            showClient={false}
            header={splitPart && splitPart}
          />
          <BlogSummary
            heading="Foeign Language Courses"
            subheading=""
            description="Everything You Need to Deliver & Monetise Content "
            data={blogData != null && blogData[0].html}
            relatedData={relatedblogData != null && relatedblogData}
            blogProds={blogProds != null && blogProds}
            blogBanners={blogBanners != null && blogBanners}
          />

          <PreFooter
            fromPage="BlogPage"
            formheading="Get in Touch With Our Academic Counsellors to Help You In Your Preparations."
            formsubheading="Book Our Free Discussion Session Today"
            heightlightText=""
          />
          <Footer />
        </AnimationRevealPage>
      </>
    )
  );
};
