import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Bajajfinserv from "../../images/clientLogos/bajajfinserv.png"
import Proptiger from "../../images/clientLogos/proptiger.png"
import Housing from "../../images/clientLogos/housing.png"
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"

const Container = styled.ul`
	${tw`relative`}
	li{
		${tw`xs:w-2/5 `}
	}

`;


export default ({
	clientLogos = [
		
	  	{
	  		logoSrc: Housing,
	  		clientName: "housing.com"
	  	},
	  	{
	  		logoSrc: Bajajfinserv,
	  		clientName: "Bajaj Finserv"
	  	},
	  	{
	  		logoSrc: Iamsnext,
	  		clientName: "IAMS"
	  	},
	  	{
	  		logoSrc: DBMI,
	  		clientName: "DBMI"
	  	}
	  ]

}) => {
  

  return (
    <Container className="wr-clients">
      	{clientLogos.map((item, index) => (
			<li>
				<img src={item.logoSrc} alt={item.clientName} />
			</li>
		))}	
    </Container>
  );
};
