import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import { serverUtilities } from "services/serverutilities.service";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import { components } from "ComponentRenderer.js";
import FAQ from "components/faqs/SingleCol.js";
import gols from "images/goals.png";
import class7 from "images/School Program - Class 7.jpg";
import upcoming from "images/upcoming batch.png";
import coursedetail from "images/course detail.png";
import VideoContentImage from "images/youngminds_language.png";
import MainFeature from "components/features/TwoColWithButton.js";
import { Helmet } from "react-helmet";



const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;
// const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
// const HighlightedText = tw.span`text-brand-500 `;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const batchHead = tw.span`text-black leading-tight`;
const Gtext=tw.span`text-brand-500 font-bold`;
const Ltext=tw.span`text-brand-700 font-bold`;
const Subheading = tw.p`tracking-widest text-textBlack text-center`;


const BoldTag = styled.span`
${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You Are - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => { 
  const [data,setdata] = useState([])
  useEffect(()=>{
console.log('hiiiiiii')
serverUtilities.date('C7').then((res)=>{
  console.log(res)
  setdata(res)
})
  },[])
  return (
    <>
    <Helmet
        title="Young Minds - Simplifying German Language Learning for Class 7 Students"
        meta={[
          {
            name: "description",
            content:
              "Live Classes for German Language for Class 7 Students",
          },
          {
            property: `og:title`,
            content: "Young Minds - Simplifying German Language Learning for Class 7 Students",
          },
          {
            property: `og:description`,
            content: "Live Classes for German Language for Class 7 Students",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/younminds_class7.jpg",
          },
          {
            property: `og:image:alt`,
            content: "YoungMinds School Program for Class 7",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"https://youngminds.pro/german-language-for-class-7",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />
    <AnimationRevealPage>
      <MainBanner roundedHeaderButton={true} 
        mainTitle="German Language for Class 7" 
        subTitle="<p>The class VII syllabus follows the communicative approach followed by the CBSE in teaching English at the middle and secondary level. The communicative approach remains the guiding factor. Grammar is now a bit important and carries maximum weightage.</p><br /><p>The VII class syllabus has also made a conscious effort to integrate information on German speaking countries into the curriculum- an essential prerequisite for creating intercultural consciousness.</p>"
        paragraph="Academic Partner - Language Pantheon"
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to know more About School Program for Class 7"
        imageSrc={class7}
        showClient= {false}
        
        />
      <Faqs
        imageSrc={upcoming}
        roundedHeaderButton={true}
        heading={<Title>Upcoming Batches - <Gtext>School Program Class 7</Gtext></Title>}
        description={<batchHead>All Courses are Conducted in <BoldTag>Online Live Interactive</BoldTag> Sessions. <br />We have two types of Batches - <BoldTag>Evening Batches & Weekend Batches</BoldTag>.</batchHead>}
        textOnLeft={false}
        buttonUrl="https://wa.me/918826622806/?text=Hi, I want to Join Fit in Deutsch Live Classroom Program..."
        buttonText="Lets Connect"
        faqs={data ? data.map((item) => ({
          question: (
            <batchHead>
              <Ltext>{item.batch_type}</Ltext> - {item.start_date}
            </batchHead>
          ),
          answer:
              `<p><b>Course Duration</b> : ${item.co_duration}</p><p><b>Weekly Classes</b> : ${item.weekly_classes}</p><p><b>Study Material</b> : Books Included</p><p><b>Backup </Gtext>Classes</b> : Recordings of Live Classes</p><p><b>Access Type</b> : Mobile App / Laptop</p>`
        })) : []}
      />


    {/* <Faqs
         imageSrc={upcoming}

        roundedHeaderButton={true}
        heading={<Title>Upcoming Batches - <Gtext>School Program Class 7</Gtext></Title>}
        description={<batchHead>All Courses are Conducted in <BoldTag>Online Live Interactive</BoldTag> Sessions. <br />We have two types of Batches - <BoldTag>Evening Batches & Weekend Batches</BoldTag>.</batchHead>}
        textOnLeft={false}
        buttonUrl="https://wa.me/918826622806/?text=Hi, I want to Join Fit in Deutsch Live Classroom Program..."
        buttonText="Lets Connect"
        faqs={[

          data.map((item) => {
            return {
              question: (
                <batchHead>
                  <Ltext>Weekday Batch</Ltext> - 15th Dec 2023, Friday, 7.00 PM
                </batchHead>
              ),
              answer:
        "<p><b>Course Duration</b> : 8 Months</p><p><b>Weekly Classes</b> : 2 to 3 Days, 1 Hour Each</p><p><b>Study Material</b> : Books Included</p><p><b>Backup </Gtext>Classes</b> : Recordings of Live Classes</p><p><b>Access Type</b> : Mobile App / Laptop</p>"
            };
          }),
          
         ]}

      /> */}
      <MainFeature
        primaryButtonText="Let's Talk"
        primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, I would like to know more about School Program Class 7"
        heading="Take the Stress Out Of Language Learning"
        description="German Language Learning for Goethe Certifications seems complicated, but it does not have to be. Young Minds and Language Pantheon takes care of the entire language learning process, enabling you to learn German quickly and easily.<p><b>Interactive Live Video Sessions</b><br />Allows students and instructors to engage in real-time, face-to-face communication, enabling a more immersive and personalized learning experience.</p><p><b>Flexibility in Learning</b><br />Accessible from Mobile / Laptops, plus recordings can be used in case u miss a session or to revise. Learn anytime, anywhere.</p><p><b>Learn from the Best Faculty</b><br />LP is the most powerful brand for German Courses in India with a faculty pool of over 100 Faculty. You are in a good company.<p><b>Multimedia Content Library</b></b><br />A diverse collection of learning resources, including videos, audio clips, and reading materials, that cater to various learning styles and reinforce language skills through engaging content and AI Enabled Tech Platform.</p>"
        subheading="Key Highlights / Features of the Programs"
        textOnLeft={true}
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-70/live_video_stress.gif"
        imageBorder={false}
        imageDecoratorBlob={false}
      />
      <TrackRecord 
        textOnLeft={false}
        heading={
          <>
             General Info About <HighlightedText>Class 7 Program</HighlightedText> 
          </>
        } description = 
          {
            <>
             <p> The German Language Program for Class 7 is designed for students <BoldTag>who want to learn foreign language</BoldTag> at an early age. This course curriculum confirms that students have gained basic language abilities and corresponds to enabling Speaking Short Sentences, Hearing and Initiate Writing in German Language.</p>
              <br />
              <BoldTag>Completing this Curriculum Demonstrates ...</BoldTag>
              <p>1. You can ask and reply about your hobbies.<br />2. You can follow your classroom instructions in German.<br />3. You should be able to describe your likes- Dislikes.<br />4. Describe Day to day activities.<br />5. Make small presentation also.</p>
              <br />
              <BoldTag>Exam Pattern of Class 7 Tests in School</BoldTag>
              <p>The Class 7 Program: CBSE Exam Pattern comprises of 6 sections - <HighlightedText>Grammar, Reading, Listening, Framing Questions, Reacting to Stimulus and Writing a Short Paragraph</HighlightedText> sections.
The exam is <BoldTag>administered and evaluated by the teacher in class</BoldTag>.</p>
            </>

          } 
          imageSrc={coursedetail}
        subheading = "First Step for Young Learners Towards German Proficiency"
        statistics = {[{
          key: "Grammar",
          value: "10 Marks"
        },
        {
          key: "Reading",
          value: "15 Marks"
        },
        {
          key: "Listening",
          value: "10 Marks"
        },
        {
          key: "Others",
          value: "15 Marks"

        }]}
        primaryButtonUrl="https://wa.me/918826622806/?text=Hi, I Would like to Know More About Class 7 Program"
      />
      <FeatureWithSteps
        subheading={<SubheadingSteps>{title}</SubheadingSteps>}
        heading={
          <>
            {heading} <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={gols}
        steps={
            [
              { 
                mainIcon:"",
                heading: "Are a Study Abroad Aspirant",
                description: "Germany is the Top Destination for Graduation and Post Graduation."
                
              },

              {
                mainIcon:"",
                heading:"Want to Learn Basics of German",
                description: "Take the First Step to German Proficiency, Open the World of Opportunities."
              },

              {  
                mainIcon:"",
                heading: "Wish to Get Global Certification",
                description: "Get an Official and Internationally recognized Goethe Certificate." 
              }
            ]
          }
      />

        
      
      <Faqs
        
        imageSrc = "https://aurumfiles.b-cdn.net/faq2.jpg"
        textOnLeft={false}
        heading="FAQ about Class 7 Program"
        showHeader={false}
        buttonUrl="https://wa.me/918826622806/?text=Hi, I need more info on Class 7 Program ..."
        buttonText="Lets Connect"
        description="Here are some frequently asked questions about School Level Class 7 Program for German, feel free to reach out in case of we missed out something."
        faqs={[{
      question: "What is the Marking Scheme in Class 7 German Test??",
      answer:
        "<p>Your annual result is evaluated with the following weightage - 20% for internal assessment, 30 % for periodical tests and 50% for the term examination.</p>"
        },
        {
      question: "How is Grammar Tested in Exam ??",
      answer:
        "<p>Tests are conducted by Subject Teachers from time to time and Grammar is assessed with the help of Multiple Choice Questions, Fill In The Blanks or other Objective type question format.</p>"
        },
        {
      question: "How is Writing Ability Tested in Exam ??",
      answer:
        "<p>A student is required to write short paragraphs, maximum 5 to 7 lines on a topic. The topics of the paragraph are generally relevant to the students’ age- group and experience.</p><p><b>Grammatical Errors Are Ignored in Evaluation</b></p>"
        },
        {
     question: "How is Reading Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to read texts such as brief notes or very short articles and complete exercises / objective questions on these texts.</p><p><b>Grammatical Errors Are Ignored in Evaluation</b></p>"
        },
        {
     question: "How is Speaking Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to read aloud / speak or frame a question on a word given by the teacher.</p><p><b>Grammatical Errors Are Ignored in Evaluation</b></p>"
        },
        {
     question: "How is Listening Ability Tested in Exam ??",
      answer:
        "<p>You will be asked to carefully listen to an audio or content read aloud by the teacher and complete exercises / objective questions.</p><p><b>Grammatical Errors Are Ignored in Evaluation</b></p>"
        }]}
      />
      <Testimonials 
        subheading = "Learn German from the Best Institute in India"
        heading = "Our Students Love Us."

        description = {
        <>
          <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is that we work with you from start to finish. We pride ourselves in being fully engaged with your german learning journey, to help you open global opportunities for education and career growth.  
        </>
        } 
        imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        textOnLeft={true}
        testimonials={[{
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
          heading: "Didn’t Expected Language Learning can be So Interesting & Engaging",
          quote:"My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
          customerName: "Showick Thorpe",
          customerTitle: "Founder, V&S Thorpes Education Inc."
          
        },
        {
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
          heading: "Love the Teaching Experience and Technology Tools",
          quote:"I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
          customerName: "Amit Chawla",
          customerTitle: "FCA, Content Creator at EY"
        },
        {
          stars: 5,
          profileImageSrc:"https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
          heading: "Individualized Attention & Effective Learning",
          quote:"We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
          customerName: "Achal Gupta",
          customerTitle: "CEO, Kiran Prakashan" 
        }


        ]}
      />

<MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.germanbylanguagepantheon.url}
          heading={<Heading>Delivering Best <Gtext>German</Gtext> Language Courses Powered by <Ltext>Language Pantheon</Ltext>  Institute</Heading>}
          description="Language Pantheon is the Best Institute for German Language Learning, having a faculty team of over 100 teachers, streaming live german courses in 40+ countries and training over 8000 learners across the globe. Language Pantheon has <b>Unparallel Track Record</b> of over 15 Years for German Language Coaching and nearly <b>100% Success Rate</b>.<p>Young Minds delivers India's Top Most German language courses on your mobiles / laptops, powered by AI Enabled App with Live and Recorded Videos</p><p>Our Courses are Focused on <b>Fit in Deustch & Other Goethe Certifications and CBSE Curriculum for Classes 7 to 12.</b></p>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
      <FastestGrowing />
      
      <FAQ
       
          subheading={<Subheading></Subheading>}
          description={'Feel Free to Get in Touch if We Missed Out Something...'}
          heading={
            <>
              FAQ about Coaching Program
            </>
          }
          faqs={[
            {
              question: "Who should do this course ?",
              answer:
                "<p>All those students who set their target to go Germany for Graduation / Post Graduation should definitely do this course.</p><p> Otherwise, this course is a starting point to learn a foreign langauge German with a Certification. German is widely used in Private & Govt Sector and thus promoted by CBSE in schools. It will surely help in your career.</p>"
            },
            {
              question: "How is your coaching program different from any other system? Why should I join your institute only?",
              answer:"<p>Our training system is based on learning concepts and applying them, Memorizing is prohibited in our teaching style.</p><p>Language learning <b>needs an environment to interact, read & speak without hesitation, learn and improvise</b> under the guidance of a faculty team with proven track record.</p><p style='padding-top:10px'><b>More than 80% people who go for the German Language exam every time are just from our academic partner institute - Language Pantheon.</b> We work with you till you succeed, because we are confident of our system and we know that a serious effort from your side will get you the Certification.</p>"
            },
            {
              question: "Do you guarantee result?",
              answer:
                "CERTAINLY.We do guarantee the result provided you follow our instructions blindly and do whatever we ask you to do. BUT LET US DEFINE THE WORD “GUARANTEE”: It doesn't mean that you will appear in the exam and you will clear it. It means that you will get the result at any cost. If for bad luck or any reason, you are not able to clear a module or two, we will provide classes again without any extra cost. We never ever charge fee in the name of wrong things. You will be with us till the date you have not received the certificate in your hands and German on your tongue."
            },
            {
              question: "What if my English is not good? Can I manage?",
              answer:
                "Yes. No need to worry about it. Even if your English is not good, you can easily manage. In other words, you are not reading one language, but two languages simultaneously in the same fees."
            },
            {
              question: "I want to take admission in Germany for Graduation. Will You help me?",
              answer:
                "Yes. We help our students till the point any consultant helps after taking the money. WE DO NOT CHARGE A SINGLE PENNY FOR IT i.e. WE DO THIS ABSOLUTELY FREE FOR OUR STUDENTS and we don't offer this support to the people who are not our students. We need to clarify here that please don't put us in the category of consultants as this is a small help/gift for our students only."
            }
          ]}
        />
      
      
      <PreFooter fromPage = "from Class 7" formheading = "Learning a Foreign Language in School Life Amplies Your Career Prospects and Personality" formsubheading = "Get More Details About the Program." heightlightText="Lets Start Learning"/>
      <Footer />




    </AnimationRevealPage>
    </>
  );
};
