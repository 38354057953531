import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";
import AnimatedText from "./AnimatedText.js";
import OurClients from "../clients/Clients.js";

import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const Container = tw.div`relative lg:px-0 xs:px-6`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto xs:py-20 md:py-20`;
const LeftColumn = tw.div`relative lg:w-6/12 text-left max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const ParagraphNew = tw.p`my-0 lg:my-0 text-base xl:text-lg`;

const seminarText = tw.p`mt-2 lg:mt-2 text-base xl:text-lg`;
const UpcomingBlock = tw.div`flex items-center py-4 space-x-6`;
const LinkButton = styled(PrimaryButtonBase)((props) => [
  tw`text-blue-700 font-bold !text-lg inline-block mx-auto md:mx-0 rounded-full`,
]);
const Content = tw.p`text-blue-700 font-bold !text-lg inline-block mx-auto md:mx-0 rounded-full`;
const Highlight = tw.span`text-left text-base xl:text-lg text-[#dc3188] font-bold pl-0 pr-2`;
const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0`}
  button {
    ${tw`w-8/12 bg-secondary-600 text-gray-100  lg:ml-0 lg:mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const ClientSection = tw.section`text-center mt-5 mb-10`;

export default ({
  roundedHeaderButton,
  mainTitle = "",
  subTitle = "",
  paragraph = "",
  imageSrc = "",
  buttonText = "",
  buttonUrl = "https://wa.me/918826622806/?text=Hi, I want to know more about German Courses & Its Benefits..",
  showClient = true,
  knowmoreUrl,
  otherText,
  mainColorText,
}) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            {mainTitle && <Heading>{mainTitle}</Heading>}
            {subTitle && (
              <Paragraph
                dangerouslySetInnerHTML={{ __html: subTitle }}
              ></Paragraph>
            )}

            <UpcomingBlock>
              {knowmoreUrl && (
                <ParagraphNew>
                  <LinkButton className="exploreBtn" as="a" href={knowmoreUrl}>
                    <Content>
                      <Highlight>{mainColorText}</Highlight>
                      {otherText}
                    </Content>
                  </LinkButton>
                  <seminarText>
                    <br />
                    Get Detailed Info from Experts on NEP 2020, Prospects of
                    Foreign Language in Global Careers.
                  </seminarText>
                </ParagraphNew>
              )}
            </UpcomingBlock>
            <Actions>
              <a href={buttonUrl} target="_blank">
                <button className="bt-round">{buttonText}</button>
              </a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={imageSrc}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        {showClient == true && (
          <>
            <AnimatedText />
            <ClientSection>
              <OurClients />
            </ClientSection>
          </>
        )}
      </Container>
    </>
  );
};
