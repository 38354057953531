import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {serverUtilities} from '../../services/serverutilities.service';

const FormContainer = styled.div`
  ${tw`relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input, textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 focus:outline-none transition duration-200`};
    ::placeholder {
      ${tw`text-white`}
    }
    &.inputStyle {
      ${tw`text-gray-700`};

      ::placeholder {
        ${tw`text-gray-700`}
      }
  }
`;

const TwoColumn = tw.div`flex flex-col justify-between`;
const Column = tw.div`xs:w-full xs:flex-col xs:m-0 flex sm:flex-row`;
const TextAreaWrapper = tw.div`flex flex-row`;
const InputContainer = tw.div`relative flex flex-col xs:w-full sm:w-1/2 sm:flex-row py-5 mt-6 sm:mr-5 lg:flex-col`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm `;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-white text-brand-100 rounded font-bold tracking-wide text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-white hover:text-brand-100 hocus:-translate-y-px`;
const ErrorLabel = tw.p`text-white text-lg mt-5`;

const FormErrors = tw.p`text-white text-sm mt-3`;
const SuccessLabel = tw.p`text-white font-bold text-2xl mt-5`;

export default ({
  messageColor="",
  fromPage = "",
  wappAllow = true,
  formActionUrl = "",
  textColor="",
}) => { console.log(fromPage);
  let fields = {};
  const [msgVal, setError] = useState('');
  const [isDisable , setDisable] = useState('0');
  const handleChange = (e) => {
    console.log(e.target.name);
    fields[e.target.name] = e.target.value; 
  }
  function gtag_report_conversion() {
    let url = window.location.href;
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-10791263118/yDEICIu_gYsDEI631pko',
        'event_callback': callback
    });
    return false;
  }
  const sendLogin = (e) => {
    console.log(fields);
    //alert ("1");
    console.log("I am clicked");
    //e.preventDefault();
    fields['fname'] = document.getElementById('fname-input').value;
    fields['lname'] = document.getElementById('lname-input').value;
    fields['phone'] = document.getElementById('phone-input').value;
    fields['email'] = document.getElementById('email-input').value;
    fields['message'] = document.getElementById('message-input').value;
    fields['refUrl'] = window.location.href;
    //e.preventDefault();
    console.log(validateForm());
    if(validateForm()) {
      console.log("valid true");
      
      serverUtilities.validateZeroBounceEmail(fields.email).then(data=>{
        // console.log(data);
        serverUtilities.addlead(fromPage, fields.fname, fields.lname, fields.email, fields.phone, fields.message, fields.refUrl, data.status).then(leadData=>{
          console.log(leadData);
          if(leadData.status == '0'){
            setError(leadData.message);
            setDisable(leadData.status);
            gtag_report_conversion();
          }
          else
          {
            setError(leadData.message);
            setDisable(leadData.status);
          }
        });
      });
    }
  }
  function validateForm(){
    let formIsValid;
    setError('');
    const Emailregex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i;
    if(fields.fname == ''){
      setError("*Please Enter Your Name");
      document.getElementById('fname-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    // if(fields.fname != null){
    //   if (fields.fname.match(/^[a-zA-Z]+$/)) {
    //     setError("*Please type only letters");
    //     document.getElementById('fname-input').focus();
    //   }
    // }
    if(fields.fname.length != ''){
      if(fields.fname.length < 3){
        setError("*Please Enter Your Name between 4 to 8 characters");
        document.getElementById('fname-input').focus();
        formIsValid = false;
        return formIsValid;
      }
    }
    if(fields.lname == ''){
      setError("*Please Enter Your Last Name");
      document.getElementById('lname-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.lname.length != ''){
      if(fields.lname.length < 3){
        setError("*Please Enter Your Last Name between 4 to 8 characters");
        document.getElementById('lname-input').focus();
        formIsValid = false;
        return formIsValid;
      }
     }
   
    if(fields.email == ''){
      setError("*Please Enter Your Email");
      document.getElementById('email-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if (fields.email !='' && Emailregex.test(fields.email) === false) {
      setError("Please Enter Valid Email");
      document.getElementById('email-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.phone == ''){
      setError("*Please Enter Your Phone Number");
      document.getElementById('phone-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.phone.length < 8){
      setError("*Incorrect Phone Number. Use ISD code with number");
      document.getElementById('phone-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.message == ''){
      setError("*Please Enter Your Message");
      document.getElementById('message-input').focus();
      formIsValid = false;
       return formIsValid;
    }
    return formIsValid = true;
  }
  return (

    <FormContainer>
      <div tw="mx-auto max-w-4xl">

        {isDisable == '0' && <form id="jhakkas">
          <TwoColumn >
            <Column>
              <InputContainer>
                <Label htmlFor="fname-input">Your Name</Label>
                <Input id="fname-input" type="text" name="fname" placeholder=" " required/>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="lname-input">Last Name</Label>
                <Input id="lname-input" type="text" name="lname" placeholder="" required/>
              </InputContainer>
            </Column>
            <Column>
              <InputContainer>
                <Label htmlFor="email-input">Email Address</Label>
                <Input id="email-input" type="email" name="email" placeholder="" required/>
              </InputContainer>
              <InputContainer>
                <Label htmlFor="phone-input">Phone</Label>
                <Input id="phone-input" type="number" name="phone" placeholder="" required/>
              </InputContainer>
              <Input id="fromPage-input" type="hidden" name="fromPage" value="{fromPage}"/>
            </Column>
          </TwoColumn>
          <TwoColumn>
            <TextAreaWrapper>
              <InputContainer tw="flex-1">
                <Label htmlFor="message-input">Tell Us About Your Self and Your Career Aspirations ?</Label>
                <TextArea id="message-input" name="message" placeholder=""/>
              </InputContainer>
            </TextAreaWrapper>
          </TwoColumn>
          
          <SubmitButton type="button" onClick={sendLogin}>Submit</SubmitButton>
        </form>}
        {msgVal !='' && (
          <div>
            {isDisable == '0' ? (
              <ErrorLabel>{msgVal}</ErrorLabel>
            ) : (
              <SuccessLabel>{msgVal}</SuccessLabel>
            )}
          </div>
        )}
      </div>
    </FormContainer>
  );
};
