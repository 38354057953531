import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import {PrimaryButton} from "components/misc/Buttons.js";
import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs text-left px-6 py-10 mt-12`}
  .imageContainer {
    ${tw`text-left rounded-none flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-left first:mt-16`}

  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-blackStep-200`}
  }

  .description {
    ${tw`mt-3 font-semibold text-black text-sm leading-loose`}
  }
  .learnBtn{
  	${tw`mt-10 px-10 py-3`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default (props) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "The Best Features, All in One Place",
      description: "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
    },
    { imageSrc: SupportIconImage, title: "Robust & Global Infrastructure", description: "Build and scale painlessly. Host sessions with thousands of viewers. Serve users everywhere in the world."},
    { imageSrc: FastIconImage, title: "Advanced use cases", description : "Our support teams can help with unique requirements. Tune resolutions, bitrates, and simulcast settings, or deploy to custom platforms." },
    { imageSrc: CustomizeIconImage, title: "Security & Encryption", description : "Encrypt, Authenticate, control video views and manage access permissions & validity." },
    { imageSrc: ReliableIconImage, title: "Content Types", description : "Videos, Public and Private Events, Live Streaming, PDFs, HTMLs and Assessments." },
    { imageSrc: SimpleIconImage, title: "Enterprise Ready", description: "99.9% Uptime Guaranteed. Eneterprise Grade Video Encryption" },
    
  ];
  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Benefits of Using <span tw="text-brand-500">Aurum Labs</span></Heading>
        {cards.map((card, i) => (
          <Column key={i} >
          {i==0?
          	<Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}{console.log(i)}</span>
                <PrimaryButton className="learnBtn">Lets Connect</PrimaryButton>
              </span>
            </Card>
           : 
           <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}{console.log(i)}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          }
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
