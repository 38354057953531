import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
//import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import VideoContentImage from "images/youngminds_language.png";
import BenefitsOfPantheon from "images/benefits_new_pantheon.png";
import Discover from "images/discover.png";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/clientLogos/iamsnext.svg";
import caseStudyProfileImage from "images/drsukrit.png"
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";
import { Helmet } from 'react-helmet';
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const MainTitle = tw.h1`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
  const Lang = tw.span`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight text-[#231f66]`;
  const Panth = tw.span`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight text-[#2fb3c0]`;
  const Lang1 = tw.span`font-bold text-black leading-tight text-[#231f66]`;
  const Panth1 = tw.span`font-bold text-black leading-tight text-[#2fb3c0]`;
  const nText = tw.span`font-bold text-black leading-tight`;
  const shead = tw.span`font-bold text-black`;
  return (
    <>
      <Helmet
      
      title='Unlock the Power of German with Language Pantheon | Yound Minds'
      
      meta={[
        {
          name: 'description',
          content: 'Unlock the Power of German with Language Pantheon German Language Courses with Young Minds',
        },
        {
          property: `og:title`,
          content: 'Unlock the Power of German with Language Pantheon | Young Minds',
        },
        {
          property: `og:description`,
          content: 'Unlock the Power of German with Language Pantheon German Language Courses with Young Minds',
        }]}
    />
      <Hero roundedHeaderButton={true} 
        mainTitle={<MainTitle>Unlock the Power of German with <Lang>LANGUAGE</Lang> <Panth>PANTHEON</Panth></MainTitle>}
        subTitle="Learn from the Leaders in Goethe Certificate Focused German Language Online Courses with LP & Young Minds"
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I Would like to Know More About German & Language Pantheon ..."
        imageSrc={VideoContentImage}
        showClient= {true}
      />
      <AnimationRevealPage>
        
        <VerticalSeprator className="v-seperator" />
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“The German language online classes we enrolled our students (for PG Medical abroad) in have been extremely helpful. The classes are flexible, tailored to our needs, and the instructors are knowledgeable and professional. We have seen a significant improvement in success rate for admissions in Germany in PG Programs.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Dr. Sukrit Sharma"
          customerTitle="Founder, CEO, IAMS Next"
          buttonUrl= ""
        />
        <MainFeature
          primaryButtonText="Know More About LP"
          primaryButtonUrl = "https://languagepantheon.com"
          heading={<shead>Benefits of Learning German with <Lang1>Language</Lang1> <Panth1>Pantheon</Panth1></shead>}
          description="<ul><li>The Only Institute in India training for <b>All German Language Levels</b> under One Roof.</li><li>100+ Faculty Team, 40+ Countries, 25K+ Successful Learners</li><li>Delivering Engaging <b>Online Live Video Courses Globally</b>, for all Levels - A1 to C2.</li><li>Complete Study Abroad Consultancy and Support for Germany</li><li>A Strong Foundation in School Paves way for Easy Success for Studying in Germany. Preparing for German Language Certification in Class 12 along with School Studies is really tough for many students.</li><li>When Your Career is at Stake, Learn from the Industry Leaders. <b>LP is the Most Powerful Brand for serious German Language Learning</b>.</li></ul>"
          subheading="Excellent Faculty Team, Proven Track Record"
          textOnLeft={false}
          imageSrc={BenefitsOfPantheon}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, I would like to Knwo More About LP German Courses..."
          heading="Focused on Globally Recognised Goethe Certifications & CBSE Boards"
          description="<ul><li>Language Pantheon is Exclusively <b>Focused on German Language Certifications</b> and <b>CBSE Curriculum</b>.</li><li>LP Offers Guarantee Programs for All Levels of German Certification Exams - A1 to C2.</li><li>Enabling Learners Qualify Goethe Certifications in Shortest Time.</li><li>Well Researched Curated Contents and Interactive Exam Preparation for CBSE / School Studies.</li><li><b>Immersive Learning Methodology</b> where students are placed in a real-world environment where they must use the language in order to communicate and solve problems.</li></ul>"
          subheading=""
          imageSrc={Discover}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <Stats/>
        
        
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'Language Pantheon - Unique Pedagogy, Proven Track Record '}
          heading={
            <>
              Frequently Asked Questions
            </>
          }
          faqs={[
            {
              question: "What makes this system different from other German language learning systems?",
              answer:
                "This system is unique because it provides a comprehensive syllabus with a focus on practical learning rather than memorization. The institute only teaches German and offers a high success rate for students who take the exam."
            },
            {
              question: "Why should I join this institute for German language learning?",
              answer:
                "You should join here if you are looking for a system that is committed to ensuring your success in learning German. The teachers are knowledgeable and dedicated to helping students achieve their goals. This institute has a proven track record of success for more than 15 Years."
            },
            
            {
              question: "What kind of commitment can I expect from this institute?",
              answer:
                "This institute provides a moral and legal commitment to ensure that students are taken through the entire syllabus until they receive their certificate. The institute believes that the student's success is their success, and they take this commitment seriously."
            },
            {
              question: "What is the impact of Video Quality on User Experience ?",
              answer:
                "Higher the Quality of the Video i.e. SD, HD or FULL HD, higher will be file size per hour. So if file size of 1 Hr of Video is 500 MB or 1 GB, it directly impacts the <b>Speed of the Broadband / Internet Required</b> by User other wise video will buffer."
            },
            {
              question: "Who can/should join these courses ?",
              answer:
                "Students aspiring for global careers, interested in pursuing studies abroad or are looking to learn something that might be helpful at a later stage, must opt for German Language Course."
            }
          ]}
        />
        <PreFooter />
        <Footer />
      </AnimationRevealPage>
      
   </>
  );
}
