import React, {useEffect,useState,useMemo} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/al_contactus.png";
import {serverUtilities} from '../../services/serverutilities.service';
import JoditEditor from 'jodit-react';
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0 lg:px-0 xs:px-10`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-left md:text-left`;

const Subheading = tw(SubheadingBase)`text-left md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`;

const Form = tw.form` text-sm flex flex-col w-full mx-auto md:mx-0`
const Input = tw.input`w-full mt-6 first:mt-0 border-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-brand-500 px-4`;
const Select = tw.select`w-full mt-6 first:mt-0 border-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-brand-500 px-4`;
const FormSection = tw.div`py-8 px-8 `;
const InputBlock =  tw.div`flex flex-row w-full space-x-6 mb-6 items-end`;
const InputControl = tw.div`flex flex-col items-start space-y-1 w-full`;
const Label = tw.label`font-semibold text-base`;
const EditorBlock = tw.div`w-1/2`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const ErrorLabel = tw.p`text-brand-700 text-xl mt-5`;


//const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const SubmitButton = tw.button`w-full sm:w-64 mt-6 py-3 bg-brand-500 text-white rounded font-bold tracking-wide text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-brand-700 hover:text-white hocus:-translate-y-px`;

export default ({
  placeholder="Enter Article content",
  subheading = "Contact Us",
  heading = <><span tw="text-brand-500">Get in Touch</span><wbr/> with us.</>,
  description = "Fill out this form to start a conversation with a Aurum team member about your Content Delivery and get your questions answered.",
  submitButtonText = "Submit",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  fromPage = "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let fields = {};
  const [msgVal, setError] = useState('');
  const [isDisable , setDisable] = useState('0');
  const [htmlVal, setHtmlVal] = React.useState('');
  const config = useMemo(() => (
    {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || 'Start typings...',
      uploader: {
        "insertImageAsBase64URI": true
      }
    }),
    [placeholder]
  );
  const url = window.location.search;
  console.log(url);

  let spliturl = '0'; //url.split('?slug=').pop();
  let splitBlogid = '0'; //url.split('?blogid=').pop();
  if (url.indexOf('?slug=')>-1)
    spliturl = url.split('?slug=').pop();

  if (url.indexOf('?blogid=')>-1)
    splitBlogid = url.split('?blogid=').pop();

  console.log(spliturl);
  console.log(splitBlogid);
  const [formData,setFormData] = useState(null);
  const [blogId,setBlogid] =useState('');
 /*let slug = url.name;*/
useEffect(()=>{
  if(spliturl!='0'){
    serverUtilities.getBlog(spliturl).then(resData=>{
        console.log(resData);
        if(resData.status=='1'){
          setFormData(resData.vals);
          //setHtmlVal(resData.vals[0].html);
          setBlogid(resData.vals[0].id);
        }
      });
  }
  if(splitBlogid!='0'){
    splitBlogid = "id-"+splitBlogid;
    serverUtilities.getBlog(splitBlogid).then(resData=>{
        console.log(resData);
        if(resData.status=='1'){
          setFormData(resData.vals);
          //setHtmlVal(resData.vals[0].html);
          setBlogid(resData.vals[0].id);
        }
      });
  }

},[])
  const handleChange = (e) => {
    console.log(e.target.name);
    fields[e.target.name] = e.target.value; 
  }
  console.log(fields);
  const sendLogin = (e) => {
    console.log(fields);
    //alert ("1");
    console.log("I am clicked");
    //e.preventDefault();
    fields['mydate'] = document.getElementById('date-input').value;
    fields['title'] = document.getElementById('title-input').value;
    fields['descrip'] = document.getElementById('description-input').value;
    fields['descrip4tag'] = document.getElementById('desctag-input').value;
    fields['keywords'] = document.getElementById('keyword-input').value;
    fields['categ'] = document.getElementById('category-input').value;
    fields['html'] = htmlVal;
    fields['cvr_img_type'] = '';//document.getElementById('urltype-input').value;
    fields['cvr_img'] = ''; //document.getElementById('url-input').value;
    fields['tags'] = document.getElementById('tags-input').value;
    fields['author_name'] = document.getElementById('author-input').value;
    fields['action_button'] = '';//document.getElementById('buttontext-input').value;
    fields['url_action_button'] = '';//document.getElementById('buttonurl-input').value;
     fields['html'] = document.getElementById('html-input').value;
    let slugVal = document.getElementById('slug-input').value;
    if(spliturl){
      fields['slug'] = spliturl;
      fields['id'] = blogId;
    }else{
      fields['id'] = '0';
      
      fields['slug'] = document.getElementById('slug-input').value;
      
    }
   
    
    /*fields['refUrl'] = window.location.href;*/
    //e.preventDefault();
    console.log(fields);
   // return;
    console.log(validateForm());
    /*return;*/
    if(validateForm()) {
      console.log("valid true");
      console.log(spliturl);
      if(spliturl!=undefined){
        serverUtilities.addBlogfeed(fromPage,fields).then(leadData=>{
          console.log(leadData);
          if(leadData.status == '0'){
            setError(leadData.message);
            setDisable(leadData.status);
          }
          else
          {
            setError(leadData.message);
            setDisable(leadData.status);
          }
        });
      }else{
        serverUtilities.addBlogfeed(fromPage,fields).then(leadData=>{
          console.log(leadData);
          if(leadData.status == '0'){
            setError(leadData.message);
            setDisable(leadData.status);
          }
          else
          {
            setError(leadData.message);
            setDisable(leadData.status);
          }
        });
      }
    }
  }
  function validateForm(){
    let formIsValid;
    setError('');
    const urlregex= /^http|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+$/i;
    const tagregex =/^\w+(,\w+)*$/;
    // if(fields.desctag != null){
    //   if (fields.desctag.match(/^[a-zA-Z]+$/)) {
    //     setError("*Please type only letters");
    //     document.getElementById('desctag-input').focus();
    //   }
    // }
    /*if(fields.desctag.length != ''){
      if(fields.desctag.length < 3){
        setError("*Please Enter Your Name between 4 to 8 characters");
        document.getElementById('desctag-input').focus();
        formIsValid = false;
        return formIsValid;
      }
    }*/
    // if(fields.lname == ''){
    //   setError("*Please Enter Your Last Name");
    //   document.getElementById('lname-input').focus();
    //   formIsValid = false;
    //   return formIsValid;
    // }
    // if(fields.lname.length != ''){
    //   if(fields.lname.length < 3){
    //     setError("*Please Enter Your Last Name between 4 to 8 characters");
    //     document.getElementById('lname-input').focus();
    //     formIsValid = false;
    //     return formIsValid;
    //   }
    //  }
    if(fields.mydate == ''){
      // setError("*Please Enter Date");
      // document.getElementById('date-input').focus();
      // formIsValid = false;
      // return formIsValid;
      fields['mydate'] = new Date().toISOString().slice(0, 10);
    }
    if(fields.title == ''){
      setError("*Please Enter Title");
      document.getElementById('title-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.descrip == ''){
      setError("*Please Enter Description");
      document.getElementById('description-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.descrip4tag == ''){
      setError("*Please Enter Description tag");
      document.getElementById('desctag-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.keywords == ''){
      setError("*Please Enter keyword");
      document.getElementById('keyword-input').focus();
      formIsValid = false;
      return formIsValid;
    }
    if(fields.html == ''){
      setError("*Please Enter keyword");
      document.getElementById('html-input').focus();
      formIsValid = false;
      return formIsValid;
    }
   
   /* if(fields.categ == ''){
      setError("*Please Enter Category");
      document.getElementById('category-input').focus();
      formIsValid = false;
      return formIsValid;
    }*/
    /*if(fields.cvr_img == ''){
      setError("*Please Enter URL");
      document.getElementById('url-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
    /*if(fields.cvr_img != '' && urlregex.test(fields.cvr_img) === false){
      setError("Please Enter Valid Url");
      document.getElementById('url-input').focus();
      formIsValid = false;
      return formIsValid;
    }*/
    /*if(fields.tags == ''){
      setError("*Please Enter Tag");
      document.getElementById('tags-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
    /*if(fields.tags != '' && tagregex.test(fields.tags)==false){
      setError("*Please Enter comma separated tags.");
      document.getElementById('tags-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
    /*if(fields.author_name == ''){
      setError("*Please Enter Author Name");
      document.getElementById('author-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
   /* if(fields.action_button == ''){
      setError("*Please Enter Button Text");
      document.getElementById('buttontext-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
  /*  if(fields.url_action_button == ''){
      setError("*Please Enter Button Url");
      document.getElementById('buttonurl-input').focus();
      formIsValid = false;
       return formIsValid;
    }*/
   /* if(fields.url_action_button != '' && urlregex.test(fields.url_action_button) === false){
      setError("Please Enter Valid Button Url");
      document.getElementById('buttonurl-input').focus();
      formIsValid = false;
      return formIsValid;
    }*/

    return formIsValid = true;
  }
  console.log(formData);
  return (
    <Container>
      <FormSection>
      <Label for="art_id">ARTICLE ID - {formData!=null ? formData[0].id:''}</Label>

      <br /><br />
        <Form action={formAction} method={formMethod}>
          <InputBlock>
            

            <InputControl>
              <Label for="date">Date</Label>
              <Input id="date-input" defaultvalue={formData!=null ? formData[0].mydate:''} type="datetime-local" name="date" placeholder="Enter Date"/>
            </InputControl>
            <InputControl>
              <Label for="title">Title</Label>
              <Input id="title-input" defaultValue={formData!=null ? formData[0].title:''} type="text" name="title" placeholder="Enter Title" required/>
            </InputControl>
            <InputControl>
              <Label for="slug">Slug </Label>
              <Input id="slug-input" defaultValue={formData!=null ? formData[0].slug:''} type="text" name="slug" placeholder="Enter Slug" required/>
            </InputControl>
          </InputBlock>
          <InputBlock>
           <InputControl>
              <Label for="description">Description</Label>
              <Input id="description-input" defaultValue={formData!=null ? formData[0].descrip:''} type="text" name="description" placeholder="Enter Description" required/>
            </InputControl>
            <InputControl>
              <Label for="dexcription4tag">Description for tag</Label>
              <Input id="desctag-input" defaultValue={formData!=null ? formData[0].descrip4tag:''} type="text" name="desctag" placeholder="Enter Description Tag" required/>
            </InputControl>
          </InputBlock>  
          <InputBlock>
           <InputControl>
              <Label for="keyword">Tags for Products</Label>
              <Input id="keyword-input" defaultValue={formData!=null ? formData[0].keywords:''} type="text" name="keyword" placeholder="Enter Keyword" required/>
            </InputControl>
            <InputControl>
              <Label for="category">Blog Category</Label>
              <Input id="category-input" defaultValue={formData!=null ? formData[0].categ:''} type="text" name="category" placeholder="Enter Category" required/>
             </InputControl>
          </InputBlock> 
          {/* 
<InputBlock>
  <InputControl>
    <Label for="urltype">Url Type</Label>
    <Select name="urltype" id="urltype-input" defaultValue={formData != null ? formData[0].cvr_img_type : ''}>
      <option value="video">Video</option>
      <option value="image">Image</option>
    </Select>
  </InputControl>
  <InputControl>
    <Label for="url">Url</Label>
    <Input id="url-input" defaultValue={formData != null ? formData[0].cvr_img : ''} type="url" name="url" placeholder="Enter Url" required/>
  </InputControl>
</InputBlock> 
*/}
 
          <InputBlock>
            <InputControl>
              <Label for="tags">Category ( CA- , German-) for Related Articles</Label>
              <Input id="tags-input" defaultValue={formData!=null ? formData[0].tags:''} type="text" name="tags" placeholder="i.e. ca-foundation,ca-inter,german-language, german-a1." required/>
            </InputControl>
            <InputControl>
              <InputControl>
              <Label for="author">Author</Label>
              <Input
                id="author-input"
                defaultValue={formData != null && formData[0].author_name ? formData[0].author_name : 'Young Minds'}
                type="text"
                name="author"
                placeholder="Enter Author Name"
                required
                readOnly
              />
            </InputControl>

            </InputControl>

          </InputBlock> 
            {/* 
<InputBlock> 
  <InputControl>
    <Label for="buttontext">Button Text</Label>
    <Input id="buttontext-input" defaultValue={formData != null ? formData[0].action_button : ''} type="text" name="buttontext" placeholder="Enter Button Text" required/>
  </InputControl>
  <InputControl>
    <Label for="buttonurl">Button Url</Label>
    <Input id="buttonurl-input" defaultValue={formData != null ? formData[0].url_action_button : ''} type="text" name="buttonurl" placeholder="Enter Button Url" required/> 
  </InputControl>  
</InputBlock> 
*/}

          <InputBlock>
             <InputControl>
              <Label for="html">Html</Label>
              <Textarea id="html-input" defaultValue={formData!=null ? formData[0].html:''} type="text" name="html" placeholder="Enter Article Text" required/>
            </InputControl>
            <Input id="fromPage-input" type="hidden" name="fromPage" value="{fromPage}"/>
          </InputBlock>

          <SubmitButton type="button" onClick={sendLogin}>{submitButtonText}</SubmitButton>
        </Form>
        <ErrorLabel>{msgVal}</ErrorLabel> 
      </FormSection> 
    </Container>
  );
};
