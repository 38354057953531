import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Modal from "react-modal";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl lg:w-2/3 sm:w-full`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap w-full lg:ml-20 md:ml-0 ml-0`;
const Column = tw.div`lg:w-1/3 sm:w-full`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`px-2 mx-2 my-4 border border-gray-300 hover:border-0`;
const Image = ({ imageSrc }) => {
  return <img src={imageSrc} alt="Blog Banner" />;
};

const Category = tw.div`mt-4 text-black font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg border-b border-gray-700 h-24`;
const Price = tw.h4``;
const DiscountPrice = tw.h4`mt-2 leading-relaxed line-through text-gray-500 text-lg`;
const OriginalPrice = tw.h4`mt-2 leading-relaxed font-bold text-lg text-black`;

const CloseButton = styled.button`
  ${tw`absolute top-[5px] right-0 m-2 p-2 text-white bg-gray-500 rounded-full hover:bg-gray-600 focus:outline-none`}
`;
const DiscountOffer = tw.h4`mt-2 text-green-700 font-bold text-lg leading-5 py-2 text-sm`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const customModalStyles = {
  content: {
    position: "fixed",
    bottom: "10px",
    left: "50%",
    right: "auto",
    transform: "translateX(-50%)",
    backgroundColor: "transparent",
    borderRadius: "4px",
    width: "90%",
    maxWidth: "600px",
    margin: "0 auto",
    outine: "none",
  },

  overlay: {
    backgroundColor: "transparent",
    zIndex: 1000,
  },
};

const customModalStyless = {
  content: {
    position: "fixed",
    top: "150px",
    left: "50%",
    right: "auto",
    transform: "translateX(-50%)",
    backgroundColor: "transparent",
    borderRadius: "4px",
    width: "90%",
    maxWidth: "600px",
    margin: "0 auto",
    border: "none",
    outline: "none",
  },

  overlay: {
    backgroundColor: "transparent",
    zIndex: 1000,
  },
};

Modal.setAppElement("#root"); // Ensure accessibility by setting the root element

export default ({ blogProds, blogBanners }) => {
  const [isImageBottomOpen, setIsImageBottomOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [bannerImageSrc, setBannerImageSrc] = useState("");

  //console.log(blogProds);
  //console.log(blogBanners);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleOpenModal("");
    }, 15000);
    //console.log(blogBanners);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Set a timer to open the ImageBottom component after 30 seconds
    const timer = setTimeout(() => {
      setIsImageBottomOpen(true);
    }, 30000); // 30000 milliseconds = 30 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleCloseImageBottom = () => {
    setIsImageBottomOpen(false);
  };

  const handleOpenModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenBannerModal = (imageSrc) => {
    setBannerImageSrc(imageSrc);
    setIsBannerModalOpen(true);
  };

  const handleCloseBannerModal = () => {
    setIsBannerModalOpen(false);
    //console.log("Close button clicked");
  };

  return (
    <Container>
      <Content className="blog_detail">
        <ThreeColumn>
          {blogProds != "0" &&
            blogProds?.map((post, index) => (
              <Column key={index}>
                <Link
                  href={"https://artamlabs.com/products/" + post.slug}
                  target="_blank"
                >
                  <Card>
                    <Image imageSrc={post.gallery[0]} />
                    <Category>{post.categ_details.name}</Category>
                    <Title>{post.name}</Title>
                    <Price>
                      <OriginalPrice>₹ {post.price}</OriginalPrice>
                      <DiscountOffer>Special Discounts Available</DiscountOffer>
                    </Price>
                  </Card>
                </Link>
              </Column>
            ))}
        </ThreeColumn>
      </Content>

      {isImageBottomOpen && (
        <Modal
          isOpen={isImageBottomOpen}
          onRequestClose={handleCloseImageBottom}
          contentLabel="Banner Modal"
          style={customModalStyles}
          className="bottomImage"
        >
          <Link href={blogBanners.sm_url} target="_blank">
            <Image imageSrc={blogBanners.sm_img} />
          </Link>
          <CloseButton onClick={handleCloseImageBottom}>Close</CloseButton>
        </Modal>
      )}

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Image Modal"
          style={customModalStyless}
        >
          <Link href={blogBanners.bb_url} target="_blank">
            <img src={blogBanners.bb_img} alt="Modal Banner" />
          </Link>
          <CloseButton onClick={handleCloseModal}>Close</CloseButton>
        </Modal>
      )}
    </Container>
  );
};
