import React, {useEffect,useRef} from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import OnlineBenefits from "components/features/ThreeColWithCenterImage.js";
import ToolsInfo from "components/tools/Tools.js";
import PracticeHearing from "components/tools/PracticeHearing.js";
import PracticeReading from "components/tools/PracticeReading.js";
import PracticeAccuracy from "components/tools/PracticeAccuracy.js";
import TranslateText from "components/tools/TranslateText.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
//import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import DesignIllustration from "images/banner.png";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
//import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import VideoContentImage from "images/youngminds_language.png";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
//import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import StrongBrandImage from "images/learn_german.png";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from 'react-helmet';
import Pronounciation from "images/icons/pronounciation.png";
import LiveClasses from "images/icons/live_classes.png";
import ExamPrepration from "images/icons/exam_prep.png";
import Hearing from "images/icons/hearing.png";
import Reading from "images/icons/reading.png";

import AcademicPartner from "images/icons/academic_partner.png";
import DynamicResources from "images/icons/dynamic_resources.png";
import UniquePedology from "images/icons/unique_pedagogy.png";
import FlexibleLearning from "images/icons/flexible_learning.png";
// const fs = require('fs');
//  <input type="file" id="filepicker" name="fileList" webkitdirectory='true' multiple  onChange={(e) => handleChange(e.target)} placeholder="Please choose directory having name" />
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const ref = useRef(null);
  const Subheading = tw.p`tracking-widest text-textBlack text-left md:text-center lg:text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;
  const Title = tw.h1`font-bold text-2xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
  const Gtext=tw.span`text-brand-500 font-bold`;
  const Ltext=tw.span`text-brand-700 font-bold`;
  const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
  
  const getSelectedId=(id)=>{
    //console.log(id);
    let secId= id.toString();
    //console.log(secId);
    const element = document.getElementById(secId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }

  }
  
  // const tawkToPropertyId = '64538ccb31ebfa0fe7fbedf0';
  // const tawkToKey = '1gvj5rvt5';
  // useEffect(() => {
  //      tawkTo(tawkToPropertyId, tawkToKey)
  //  }, [])
  return (
    <>
      <Helmet
      
      title='Young Minds - Simplifying German Language Learning'
      
      meta={[
        {
          name: 'description',
          content: 'Streamline and Simplify German Language Learning for Global Career Goals',
        },
        {
          property: `og:title`,
          content: 'Young Minds - Simplifying German Language Learning',
        },
        {
          property: `og:description`,
          content: 'Streamline and Simplify German Language Learning for Global Career Goals',
        }]}
    />
   

    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} 
        mainTitle={<Title>Global Careers Made Super Easy with <br /><Gtext>German</Gtext> <Ltext>Language</Ltext></Title>} 
        subTitle="Let your <b>Global Career Goals</b> turn into reality with unmatched Experience, Track Record & Learning Flexibility."
        buttonText="Lets Talk"
        imageSrc={DesignIllustration}
        showClient= {true}
      />
      
        <Features
          subheading={<Subheading><Gtext>GERMAN</Gtext> <Ltext>LANGUAGE Platform</Ltext> that makes Success in  Goethe Exams Easier than Ever</Subheading>}

          heading={
            <>
              One Language - <Gtext>German</Gtext>, Global Career Potential 
            </>
          }
        />
        <VerticalSeprator className="v-seperator" />
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText = '“Our company recently partnered with Young Minds for German language online classes for our employees, and we have been thoroughly impressed with the results. The classes are tailored to meet the needs of our team, and the instructors are knowledgeable, professional, and accommodating.”'
          profileImageSrc={caseStudyProfileImage}
          customerName="Deepak Tekchandani"
          customerTitle="Founder, CEO"
          buttonUrl= ""
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.prospectsofgermanlanguage.url}
          heading={<Heading>Develop Strong <Gtext>German</Gtext> <Ltext>Language Foundation</Ltext> with Unique Pedagogy</Heading>}
          description="Boost Your Global Career Goals by Clearing Goethe Certificate Exams - Fit in Deutsch for School Learners. Clearing <b>Gothe Certificate Exams establishes Your Strong German Language Foundation</b> which is a boon when you wish to Study Abroad or Aspire for Global Careers.<br/><br />  Get the best, Most Researched and Unique Learning Experience as we understand German Learning requires Exceptional Teaching Methodology and Language Learning Framework.<p>The platform offers a comprehensive curriculum covering all aspects of German language learning, including grammar, vocabulary, reading, writing, listening, and speaking.</>"
          subheading=""
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl = {innerPages.germanbylanguagepantheon.url}
          heading={<Heading>Delivering Best <Gtext>German</Gtext> Language Courses Powered by <Ltext>Language Pantheon</Ltext>  Institute</Heading>}
          description="Language Pantheon is the Best Institute for German Language Learning, having a faculty team of over 100 teachers, streaming live german courses in 40+ countries and training over 8000 learners across the globe. Language Pantheon has <b>Unparallel Track Record</b> of over 15 Years for German Language Coaching and nearly <b>100% Success Rate</b>.<p>Young Minds delivers India's Top Most German language courses on your mobiles / laptops, powered by AI Enabled App with Live and Recorded Videos</p><p>Our Courses are Focused on <b>Fit in Deustch & Other Goethe Certifications and CBSE Curriculum for Classes 7 to 12.</b></p>"
          subheading=""
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
          textOnLeft={true}
        />
        
        <OnlineBenefits />
        <ToolsInfo selectedId={getSelectedId}/>
        
        <MainFeature
          
          heading="AI Enabled, Advanced Tech Platform for Language Learning"
          description="<p><b>Personalized Learning</b>: The platform uses machine learning algorithms to personalize the learning experience for each student, based on their strengths, weaknesses, and learning pace.</p><p><b>Interactive Content</b>: The platform offers interactive content such as videos, audio clips, and quizzes that engage students and make learning German fun.</p><p><b>Speech Recognition</b>: The platform uses speech recognition technology to help students improve their pronunciation and speaking skills.</p><p><b>Flexibility & Multi-device Support</b>: The platform is available 24/7, allowing students to learn at their own pace and platform is accessible on multiple devices such as smartphones, tablets, and laptops, making it easy for students to learn on-the-go.</p>"
          subheading=""
          textOnLeft={false}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <WhyChooseWrap>
          <WhyChoose 
            heading="Young Minds"
            subheading="Unlimited possibilities and Uniquely Great Experience Every Time"
            noCardSpace={true}
            cards={[
              {
                imageSrc: DynamicResources,
                subText: "Reading, Writing, Hearing and Speaking - All Round Learning as per Global Standards.",
                title: "All Round Learning",
                
              },
              {
                imageSrc: UniquePedology,
                subText: "Well Researched LP Methodology to Learn German in Fastest and Most Effective Manner.",
                title: "Unique Pedagogy",
                
              },
              {
                imageSrc: AcademicPartner,
                subText: "Learn Under the Guidance of India's Best German Language Institue - <b>Language Pantheon</b>",
                title: "Best Academic Partner",
                
              },
              {
                imageSrc: Pronounciation,
                subText: "AI Enabled Pronunciation Assessment for Interactive & Enhanced Learning.",
                title: "Pronunciation",
                
              },
              {
                imageSrc: Hearing,
                subText: "Advanced Tech Enabled Platform for Listening Practice with Intsant Feedback.",
                title: "Hearing Assessment",
                
              },
              {
                imageSrc: Reading,
                subText: "Speak Along - AI Enabled Practice to Learn Speaking without Hesitations.",
                title: "Reading Practice",
                
              },
              {
                imageSrc: FlexibleLearning,
                subText: "Live Classes, Recorded Classes and Interactive Sessions, Learn Anytime Anywhere.",
                title: "Flexible Learning",
                
              },
              
              {
                imageSrc: LiveClasses,
                subText: "Live Interactive Classes with India's Best Academic Team for German Language - LP.",
                title: "Live Classes",
                
              },
              {
                imageSrc: ExamPrepration,
                subText: "Take a Leap and Prepare for Global Goethe Certifications.",
                title: "Exam Prepration",
                
              },
              
              
              
            ]}
            isAnimate = {true}
          />
        </WhyChooseWrap>
        <PreFooter fromPage="Home Page"/>
      
      <Footer />
      </AnimationRevealPage>
   </>
  );
}
