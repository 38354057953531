import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
//import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { LinkButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as ArrowForward } from "images/arrowForward.svg";

const Container = tw.div`relative xs:px-10 lg:px-0 xs:text-center`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 xs:text-left md:text-left`;

const Subheading = tw(SubheadingBase)`text-left md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-left md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`;

const Statistics = tw.div`grid grid-cols-2 gap-4 items-center sm:block text-left md:text-left mt-4`;
const Statistic = tw.div`lg:text-left xs:text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-brand-500`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`bg-brand-500 hover:bg-brand-700 mt-12 text-sm inline-block mx-auto md:mx-0 rounded-full`,
  props.buttonRounded && tw`rounded-full`
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);
const LinkButton = styled(PrimaryButtonBase)(props => [
  tw`bg-brand-500 hover:bg-brand-700 hover:text-white mt-12 text-sm inline-block mx-auto md:mx-0 rounded-full text-white p-2 px-5`,
  props.buttonRounded && tw`rounded-full`
]);
const IconContainer = tw.i``;
export default ({
  subheading = "Our Track Record",
  heading = (
    <>
      We have been doing this <wbr /> since <span tw="text-primary-500">1999.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Let's Talk",
  primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, I Would like to Know More About Fit in Deutsch A1 Program",
  buttonRounded = false,
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Reading",
      value: "20 Mins"
    },
    {
      key: "Listening",
      value: "20 Mins"
    },
    {
      key: "Writing",
      value: "20 Mins"
    },
    {
      key: "Speaking",
      value: "15 Mins"
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            {primaryButtonText && 
              <LinkButton buttonRounded={buttonRounded} className="exploreBtn" as="a" href={primaryButtonUrl}>
                {primaryButtonText} <IconContainer><ArrowForward className="btnArrow" /></IconContainer>
              </LinkButton>
            }
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
