import React, {useRef, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; 
// import { ReactComponent as Hearing } from "images/hearing.svg";
import Lpimage from "../../images/clientLogos/languagePantheon.png"
import Gradeup from "../../images/clientLogos/gradeup.png"
import Iamsnext from "../../images/clientLogos/iamsnext.svg"
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png"
import Kiranprakashan from "../../images/clientLogos/kiranprakashan.png"
import DBMI from "../../images/clientLogos/dbmi.png"
import Igp from "../../images/clientLogos/igp.png"
import Hearing from "../../images/hearing.svg"
import Reading from "../../images/reading.svg"
import Speak from "../../images/speaker.png"
import Mic from "../../images/mic.png"
import { SectionHeading } from "components/misc/Headings.js";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"
import { useSpeechSynthesis  } from 'react-speech-kit';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
const Section = styled.div`
	${tw`relative xs:px-10 py-20 md:py-10 bg-white`}
`;
const Container = styled.div`
	${tw`relative text-left max-w-[1280px] mx-auto`}
`;
const IconContainer = tw.i``;
const Heading = tw(SectionHeading)`w-full  md:mb-20 max-w-[1170px] mx-auto`;
const MainBlock = styled.div`
	${tw`border border border-gray-400 mt-10`}
	h3{
		${tw`text-center mx-auto text-xl md:text-4xl font-semibold text-blackStep-200 p-5`}
	}
	p{
		${tw`text-center mx-auto text-base font-semibold text-gray-400 p-5`}
	}
`;
const TextBlock = styled.div`
	${tw`border border border-gray-400 border-t-0 mb-4`}
	p{
		${tw`text-center mx-auto text-sm md:text-base font-normal md:font-semibold text-gray-600 p-2 md:p-5`}
	}
`;
const SpeakSection = styled.div`
	${tw`flex justify-center gap-4`}
	a{
		${tw`text-center `}
	}
	img{
		${tw`mx-auto`}
	}
	p{
		${tw`mt-2 text-blackStep-200 font-normal md:font-semibold`}
	}
`;
export default ({
	heading = "Speak in German and Check Accuracy",

}) => {
  const germanPara= 'Alles klar, bis morgen!';
  const [paraText,setParaText] = useState(germanPara);
  const [value, setValue] = useState('');
  const [show,setShow] = useState(false);
  const { speak, cancel, voices } = useSpeechSynthesis();
  const [lang, setLang] = useState('de-DE');
  const microphoneRef = useRef(null);
   const {
    
    listening,
   
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  /*const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);

    },
  });*/
  const { transcript, resetTranscript } = useSpeechRecognition();
  function handleListing() {
   // setIsListening(true);
   resetTranscript('');
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: 'de-DE'
    });
    setShow(!show);
   // setStatus_lang('disabled');
  };
  const stopHandle = () => {
   /* setIsListening(false);*/
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
   /* setStatus_lang('');*/
    
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };
  const handleResetSpeak =()=>{
   // setBaseText('');
   // setTransText('');
    //setAppear(false);
    stopHandle();
    resetTranscript();
  }
  const handleMic= ()=>{
  	handleListing();
  	if(listening == true){
  		stopHandle();
  	//	setValue("");
    //  listen({lang});
  	}
  	console.log(listening);
	setShow(!show);
  }

  const handleSpeak =()=>{
	if(show==false){
		speak({ text: paraText, voice: voices[5] });
		setShow(!show);
	}else{
		cancel();
	setShow(!show);
	}
	
 }
 	const getSimilarity = (s1) => {
        var longer = s1;
        var s2 = "Alles klar, bis morgen!";
        var shorter = ""; //s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
    }
 	function editDistance(s1, s2) {
        //s1 = s1.toLowerCase();
        //s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                    costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }
 
  return (
  	<Section>
	    <Container>
	    	<Heading>{heading}</Heading>
	    	<MainBlock>
				<h3>{germanPara}</h3>		
			</MainBlock>
			<TextBlock>
				<p>{transcript == "" ? "Click on the Mic to speak above passage and check accuracy." : transcript}</p>
			</TextBlock>
			<SpeakSection>
				<a href="javascript:void(0)" className="" onClick={() => handleSpeak()}>
					<img src={Speak} />
					<p>Let me Speak</p>
				</a>
				<a href="javascript:void(0)" className="" ref={microphoneRef} onClick={() => handleListing()}>
					<img src={Mic} />
					<p>Click and Speak</p>
				</a>
			</SpeakSection>
			{listening && <div style={{textAlign:'center',marginTop:15,fontWeight:600}}>Go ahead I'm listening</div>}
            {transcript != '' && (
            	<div id="accuracy">Your Accuracy <span style={{fontSize:40, color:"#333"}}  class="greenText">{getSimilarity(transcript).toFixed(2)}</span></div>
            )}
	    </Container>
	</Section>
  );
};
