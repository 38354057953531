// import Cookies from 'universal-cookie';
//import {environment} from '../environment.js';
//import { authHeader } from '../_helpers';
//import {Md5} from 'ts-md5/dist/md5';
// const cookies = new Cookies();
import axios from "axios";

const apiURL = "https://aurumlabs.co/olv/mobapp/";
const ArgentAPIurl = "https://v1.artamlabs.com/olv/mobapp/";
export const serverUtilities = {
  login,
  addlead,
  addBlogfeed,
  validateZeroBounceEmail,
  getBlog,
  getBlogList,
  getBlogProds,
  date,
};

// function getCurrentTime(){
//   return Math.round(new Date().getTime()/1000);
// }
async function getBlogList(slug, counter) {
  console.log(slug);
  const URI =
    "https://aurumlabs.co/olv/mobapp/admin/get_blog_list/" +
    slug +
    "/" +
    counter;
  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      return res;
    });
}

async function getBlog(slug) {
  console.log(slug);
  const URI = "https://duxyz.b-cdn.net/0/" + slug;
  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      return res;
    });
}

async function getBlogProds(slug) {
  console.log(slug);
  const URI =
    "https://argentapi.b-cdn.net/user/getBlogRelatedProds/blog/1/1122/" + slug;
  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      return res;
    });
}
function validateZeroBounceEmail(email) {
  let zeroBounceKey = "a5ad928dd58b4216961881265a7ab4b3";

  let ip = "99.123.12.122";

  let URI =
    "https://api.zerobounce.net/v2/validate?api_key=" +
    zeroBounceKey +
    "&email=" +
    email +
    "&ip_address=" +
    ip;
  const requestOptions = {
    method: "GET",
    headers: {},
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);

      return res;
    });
}
function addlead(
  fromPage,
  fname,
  lname,
  email,
  phone,
  msg,
  refurl,
  emailStatus
) {
  let URI = apiURL + "admin/insertLeads/32423423";

  //add time
  //add hash
  //enable complete validation

  //console.log(name);
  console.log(refurl);
  let jsonData = JSON.stringify({
    page: fromPage,
    email: email,
    phone: phone,
    firstname: fname,
    lastname: lname,
    refurl: refurl,
    msg: msg,
    timeExp: "1000000",
    time: "",
    hash: "",
    emailStatus: emailStatus,
  });
  let body = jsonData;
  console.log(body);
  const requestOptions = {
    method: "POST",
    headers: {},
    body: body,
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log(user);

      return user;
    });
}
function addBlogfeed(fromPage, fields) {
  console.log(fields);
  let URI = apiURL + "admin/ins_update_blog/32423423";

  let jsonData = JSON.stringify({
    id: fields.id,
    page: fromPage,
    mydate: fields.mydate,
    title: fields.title,
    descrip: fields.descrip,
    descrip4tag: fields.descrip4tag,
    keywords: fields.keywords,
    categ: fields.categ,
    html: fields.html,
    cvr_img_type: fields.cvr_img_type,
    cvr_img: fields.cvr_img,
    tags: fields.tags,
    author_name: fields.author_name,
    action_button: fields.action_button,
    url_action_button: fields.url_action_button,
    slug: fields.slug,
    timeExp: "1000000",
    time: "",
    hash: "",
  });
  console.log(jsonData);
  /* return;*/
  let body = jsonData;
  console.log(body);
  const requestOptions = {
    method: "POST",
    headers: {},
    body: body,
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log(user);

      return user;
    });
}
function login(mode, username, password) {
  let URI = apiURL + "admin/adminWebL/32423423";

  let jsonData = JSON.stringify({
    signin_mode: mode,
    email: username,
    phone: "",
    password: password,
    model: "0",
    fcmId: "",
    accessFrom: "BROW",
    main_group: "120990",
    timeExp: "20",
    time: "",
    hash: "",
  });
  let body = jsonData;
  console.log(body);
  const requestOptions = {
    method: "POST",
    headers: {},
    body: body,
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log(user);
      return user;
    });
}
function logout() {
  // remove user from local storage to log user out
  //localStorage.removeItem(environment.LocalstorageKey);
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function date(cla, fd) {
  // let URI = 'https://duxyz.b-cdn.net/0/getNBlevel/879';
  let URI = apiURL + "admin/getupcomingbatch/32423423";

  let jsonData = JSON.stringify({
    cl: cla,
  });
  let body = jsonData;
  console.log(body);
  const requestOptions = {
    method: "POST",
    headers: {},
    body: body,
  };
  return fetch(URI, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log(user);
      return user;
    });
}
