import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/FastestGrowing.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import { components } from "ComponentRenderer.js";
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;

const BoldTag = styled.span`
   ${tw`text-textBlack font-bold`}
`;
export default ({
 title = "Who Should Do It ??",
 highlightedtext = "You Are - ",
 heading = "Ideal If ",
 CheckIcon = Checkicon,
 innerPages = components.innerPages,
}) => { 
  return (
    <AnimationRevealPage>
      <MainBanner roundedHeaderButton={true} 
        mainTitle="Young B1" 
        subTitle="The Fit in Deutsch 1 exam is designed for children and adolescents aged between 10 and 16 who want to test their German language proficiency. This certification confirms that candidates have gained fundamental language abilities and corresponds to level A1 on the Common European Framework of Reference for Languages (CEFR) six-level competence scale."
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to GROW my Business with Aurum Content Delivery Platform."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
        showClient= {true}
        text="Fit In Deutsch 1"
        knowmoreUrl={innerPages.aboutfitindeutsch1.url}
        />
      <FeatureWithSteps
        subheading={<SubheadingSteps>{title}</SubheadingSteps>}
        heading={
          <>
            {heading} <HighlightedText>{highlightedtext}</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/easy_onboarding.gif"
        steps={
            [
              { 
                mainIcon:"",
                heading: "Ready to Go",
                description: "Take a German exam for children and young people between 10 and 16 years of age "
                
              },

              {
                mainIcon:"",
                heading:"Learn Basics of German",
                description: "Take the First Step to German Proficiency for Study Abroad."
              },

              {  
                mainIcon:"",
                heading: "Get Certified",
                description: "Get an official and internationally recognized Goethe certificate" 
              }
            ]
          }
      />
      <TrackRecord 
        textOnLeft={true}
        heading={
          <>
            Why <HighlightedText>Aurum</HighlightedText> is the Best Choice
          </>
        } description = 
          {
            <>
              Its the New Front Door for the Brands to Deliver Content Across Globe on Almost All Devices. Aurum makes it easy for the Content Owners and Developers to Build Faster and Manage Delivery at Scale.<p>&nbsp;</p><p>It is the easiest and fastest way to Streamline, Modernise and Monetise Content. <BoldTag>Let’s build better digital experiences together.</BoldTag></p>
            </>

          } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-50/why_aurum_2.png"
        subheading = "The Modern Content Delivery & Monetisation Platform"
      />
      <Faqs
        textOnLeft={false}
        buttonUrl="https://wa.me/918826622806/?text=Hi, How can i GROW my Digital Content Business with Aurum"
        buttonText="Lets Connect"
      />
      <Testimonials description = {
        <>
          We work with you from start to finish. We view ourselves as an extension of your team, and as your partner, we pride ourselves in being fully engaged with your company to help you find opportunities for growth. <BoldTag>The Reason why Content Owners Love Aurum</BoldTag> 
        </>
        } imageSrc = "https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
        textOnLeft={true}
      />
      <FastestGrowing />
      <PreFooter fromPage = "Content Delivery Platform" formheading = "Streamlining & Managing Content Storage and Distribution Amplifies Team Efficiency and Sales" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="The Benefits of Using a Structured Content Distribution Platform"/>
      <Footer />
    </AnimationRevealPage>
  );
};
